// @flow
import * as React from "react";
import { useThesesStyles } from "./styles";
import { commitMutation } from "react-relay";
import type { IEnvironment } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import {
  Button,
  Form,
  Row,
  SelectInput,
  SubmitInput,
  TextInput,
} from "@jpbarela/arachnae";
import { colors } from "../../../theme";

type CreateThesisFormProps = {
  onCancel: () => void,
  environment: IEnvironment,
};

function ErrorMessage({ error }) {
  if (error) {
    const classes = useThesesStyles();
    return <div className={classes.error}>{error}</div>;
  }

  return null;
}

export function CreateThesisForm({
  onCancel,
  environment,
}: CreateThesisFormProps): React.Node {
  const classes = useThesesStyles();
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState("");

  function handleCompletion(response) {
    setCreating(false);
    if (response.createThesis.errors.length > 0) {
      setError(response.createThesis.errors[0].description);
    } else {
      onCancel();
    }
  }

  function handleError(err) {
    setCreating(false);
    setError(err.message);
  }

  function submitMutation({ quick, ticker, sentiment }) {
    setCreating(true);

    const variables = {
      createThesisInput: {
        thesis: {
          quick,
          sentiment,
          ticker,
        },
      },
    };

    commitMutation(environment, {
      mutation: createThesisMutationGraphql,
      variables,
      configs: mutationConfigs,
      onCompleted: handleCompletion,
      onError: handleError,
    });
  }

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submitMutation}
        container={{ borderBottom: `solid 2px ${colors.lightBlue}` }}
      >
        <Row justifyContent="start">
          <TextInput
            name="ticker"
            required={true}
            placeholder="Ticker"
            width="5rem"
            testID="ticker"
          />
          <TextInput
            name="quick"
            required={true}
            placeholder="Why do think this stock is worth investing in?"
            width="50rem"
            testID="quick"
          />
          <SelectInput
            name="sentiment"
            options={[
              {
                value: "positive",
                name: "Good Investment",
                testID: "positive",
              },
              {
                value: "neutral",
                name: "Neutral Investment",
                testID: "neutral",
              },
              {
                value: "negative",
                name: "Bad Investment",
                testID: "negative",
              },
              {
                value: "draft",
                name: "In progress",
                testID: "draft",
              },
            ]}
            required={true}
            testID="sentiment"
          />
          <SubmitInput
            name={creating ? "Creating Thesis ..." : "Create Thesis"}
            disabled={creating}
            testID="create"
          />
          {!creating ? (
            <Button type="link" onClick={onCancel} name="Cancel" />
          ) : null}
          <ErrorMessage error={error} />
        </Row>
      </Form>
    </div>
  );
}

const mutationConfigs = [
  {
    type: "RANGE_ADD",
    parentID: "client:root:me",
    connectionInfo: [
      {
        key: "Theses_theses",
        rangeBehavior: "append",
      },
    ],
    edgeName: "thesisEdge",
  },
];

const createThesisMutationGraphql = graphql`
  mutation CreateThesisForm_CreateThesisMutation(
    $createThesisInput: CreateThesisInput!
  ) {
    createThesis(input: $createThesisInput) {
      thesisEdge {
        node {
          id
          quick
          stock {
            ticker
            name
          }
        }
      }
      errors {
        code
        description
      }
    }
  }
`;
