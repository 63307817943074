// @flow
import * as React from "react";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  IEnvironment,
} from "relay-runtime";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

type AuthenticationContextValue = {
  environment?: IEnvironment,
  isAuthenticated: boolean,
  logout: ({ returnTo: string }) => void,
  user: {
    given_name: string,
  },
};

const AuthenticationContext = React.createContext(
  ({}: AuthenticationContextValue)
);

function createEnvironment(token): IEnvironment {
  function fetchQuery(operation, variables) {
    if (!process.env.REACT_APP_HOST) {
      throw new Error();
    }
    return fetch(process.env.REACT_APP_HOST + "/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    }).then((response) => {
      return response.json();
    });
  }

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
}

function EnvironmentContainer({
  children,
}: {
  children?: React.Node,
}): React.Node {
  const [environment, setEnvironment] = React.useState();
  const { getAccessTokenSilently, isAuthenticated, logout, user } = useAuth0();

  async function createAuthenticatedEnvironment() {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        const newEnvironment = createEnvironment(token);
        setEnvironment(newEnvironment);
      } catch {}
    }
  }

  React.useEffect(() => {
    createAuthenticatedEnvironment();
  }, [getAccessTokenSilently]);

  return (
    <AuthenticationContext.Provider
      value={{ environment, isAuthenticated, logout, user }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function AuthenticationProvider({
  children,
}: {
  children?: React.Node,
}): React.Node {
  // Provides a wrapper around Auth0 and relay to enable easy access of authenticated environment for GraphQL queries

  return (
    <Auth0Provider
      domain="eumetric.us.auth0.com"
      clientId="LNWyFhW3H17z1UX7OidsNyr5KATx4zVY"
      audience="https://thesivest-api"
      redirectUri={window.location.origin}
    >
      <EnvironmentContainer>{children}</EnvironmentContainer>
    </Auth0Provider>
  );
}

export function useAuthentication(): AuthenticationContextValue {
  return React.useContext(AuthenticationContext);
}
