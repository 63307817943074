/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Sentiment = "draft" | "negative" | "neutral" | "positive" | "%future added value";
export type CreateThesisInput = {|
  clientMutationId?: ?string,
  thesis: ThesisCreateInput,
|};
export type ThesisCreateInput = {|
  quick: string,
  sentiment: Sentiment,
  ticker: string,
|};
export type CreateThesisForm_CreateThesisMutationVariables = {|
  createThesisInput: CreateThesisInput
|};
export type CreateThesisForm_CreateThesisMutationResponse = {|
  +createThesis: ?{|
    +thesisEdge: ?{|
      +node: ?{|
        +id: string,
        +quick: string,
        +stock: {|
          +ticker: string,
          +name: string,
        |},
      |}
    |},
    +errors: $ReadOnlyArray<{|
      +code: string,
      +description: ?string,
    |}>,
  |}
|};
export type CreateThesisForm_CreateThesisMutation = {|
  variables: CreateThesisForm_CreateThesisMutationVariables,
  response: CreateThesisForm_CreateThesisMutationResponse,
|};
*/


/*
mutation CreateThesisForm_CreateThesisMutation(
  $createThesisInput: CreateThesisInput!
) {
  createThesis(input: $createThesisInput) {
    thesisEdge {
      node {
        id
        quick
        stock {
          ticker
          name
          id
        }
      }
    }
    errors {
      code
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createThesisInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "createThesisInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quick",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticker",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateThesisForm_CreateThesisMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateThesisPayload",
        "kind": "LinkedField",
        "name": "createThesis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ThesisEdge",
            "kind": "LinkedField",
            "name": "thesisEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Thesis",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stock",
                    "kind": "LinkedField",
                    "name": "stock",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateThesisForm_CreateThesisMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateThesisPayload",
        "kind": "LinkedField",
        "name": "createThesis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ThesisEdge",
            "kind": "LinkedField",
            "name": "thesisEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Thesis",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stock",
                    "kind": "LinkedField",
                    "name": "stock",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec8b0a0a64aa4d58171103a9a41383c9",
    "id": null,
    "metadata": {},
    "name": "CreateThesisForm_CreateThesisMutation",
    "operationKind": "mutation",
    "text": "mutation CreateThesisForm_CreateThesisMutation(\n  $createThesisInput: CreateThesisInput!\n) {\n  createThesis(input: $createThesisInput) {\n    thesisEdge {\n      node {\n        id\n        quick\n        stock {\n          ticker\n          name\n          id\n        }\n      }\n    }\n    errors {\n      code\n      description\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e082648aadefac650bc9bc3440996425';

module.exports = node;
