// @flow
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import type { ThesesTable_thesis } from "./__generated__/ThesesTable_thesis.graphql";
import { useThesesStyles } from "./styles";
import type { IEnvironment } from "relay-runtime";
import { LoadingComponent } from "../LoadingComponent";
import { QueryRenderer, createFragmentContainer } from "react-relay";
import { Row } from "@jpbarela/arachnae";

type ThesesTableProps = {
  environment: IEnvironment,
};

function ThesisRow({ thesis }: { thesis: ThesesTable_thesis }) {
  const classes = useThesesStyles();

  return (
    <Row justifyContent="start">
      <div className={classes.ticker}>{thesis.stock.ticker}</div>
      <div className={classes.name}>{thesis.stock.name}</div>
      <div>{thesis.quick}</div>
    </Row>
  );
}

const ThesisRowFragmentContainer = createFragmentContainer(ThesisRow, {
  thesis: graphql`
    fragment ThesesTable_thesis on Thesis {
      quick
      stock {
        ticker
        name
      }
    }
  `,
});

function ThesesRows({ rows }) {
  const classes = useThesesStyles();

  return (
    <div className={classes.container}>
      {rows.map((edge) => (
        <ThesisRowFragmentContainer key={edge.node.id} thesis={edge.node} />
      ))}
    </div>
  );
}

export function ThesesTable({ environment }: ThesesTableProps): React.Node {
  const classes = useThesesStyles();

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query ThesesTableQuery {
          me {
            theses(first: 20) @connection(key: "Theses_theses") {
              edges {
                node {
                  id
                  ...ThesesTable_thesis
                }
              }
            }
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <LoadingComponent className={classes.container} />;
        }
        return (
          <div>
            {props.me.theses.edges.length ? (
              <ThesesRows rows={props.me.theses.edges} />
            ) : (
              <ThesisCallToAction />
            )}
          </div>
        );
      }}
    />
  );
}

function ThesisCallToAction() {
  const classes = useThesesStyles();

  return (
    <div className={classes.container}>No theses found. Create a thesis!</div>
  );
}
