// @flow
import * as React from "react";
import type { IEnvironment } from "relay-runtime";
import { LoadingComponent } from "../LoadingComponent";
import { Button, SectionHeading } from "@jpbarela/arachnae";
import { TabPanel } from "react-tabs";

type PanelProps = {
  Body: React.ComponentType<{ environment: IEnvironment }>,
  Form: React.ComponentType<{
    environment: IEnvironment,
    onCancel: () => void,
  }>,
  buttonName: string,
  environment?: IEnvironment,
  panelName: string,
};

export function Panel({
  Body,
  Form,
  buttonName,
  environment,
  panelName,
  ...otherProps
}: PanelProps): React.Node {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <TabPanel {...otherProps}>
      <ErrorBoundary>
        <SectionHeading>{panelName}</SectionHeading>
        {environment ? (
          <Body environment={environment} />
        ) : (
          <LoadingComponent />
        )}
        <Button onClick={() => setShowForm(true)} name={buttonName} />
        {showForm && environment ? (
          <Form onCancel={() => setShowForm(false)} environment={environment} />
        ) : null}
      </ErrorBoundary>
    </TabPanel>
  );
}

Panel.tabsRole = "TabPanel";

class ErrorBoundary extends React.Component<
  { children: React.Node },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <SectionHeading>
          We're sorry something has gone wrong. Please contact support if this
          continues.
        </SectionHeading>
      );
    }

    return this.props.children;
  }
}
