// @flow
import { createUseStyles } from "react-jss";
import { colors } from "../../../theme";

export const useHoldingsStyles: () => {
  container: string,
  currentValue: string,
  error: string,
  invested: string,
  name: string,
  percentDecrease: string,
  percentIncrease: string,
  price: string,
  shares: string,
  ticker: string,
} = createUseStyles({
  container: {
    padding: "0 1rem",
  },
  currentValue: {
    width: "10rem",
  },
  error: {
    color: colors.red,
  },
  invested: {
    width: "10rem",
  },
  name: {
    width: "20rem",
  },
  percentDecrease: {
    color: colors.red,
  },
  percentIncrease: {
    color: colors.green,
  },
  price: {
    width: "6rem",
  },
  shares: {
    width: "10rem",
  },
  ticker: {
    width: "5rem",
  },
});
