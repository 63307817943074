// @flow
import * as React from "react";

export function LoadingComponent({
  className,
}: {
  className?: string,
}): React.Node {
  return <div className={className}>Loading</div>;
}
