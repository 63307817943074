// @flow
import * as React from "react";
import { ThemeProvider } from "react-jss";
import { theme } from "./theme";
import { AuthenticationProvider, useAuthentication } from "./Contexts";
import { LoginPage, Dashboard } from "./Pages";

function AuthenticatedWrapper() {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return <Dashboard />;
}

function App(): React.Node {
  return (
    <AuthenticationProvider>
      <ThemeProvider theme={theme}>
        <AuthenticatedWrapper />
      </ThemeProvider>
    </AuthenticationProvider>
  );
}

export default App;
