// @flow
import * as React from "react";
import { Button, Page, Row, Title } from "@jpbarela/arachnae";
import { useAuth0 } from "@auth0/auth0-react";

function LoginLink() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      onClick={() => loginWithRedirect()}
      style="link"
      name="Click here to login"
    />
  );
}

export function LoginPage(): React.Node {
  return (
    <Page>
      <Row justifyContent="center">
        <Title>ThesiVest</Title>
      </Row>
      <Row justifyContent="center">
        <LoginLink />
      </Row>
    </Page>
  );
}
