// @flow
import { defaultTheme } from "@jpbarela/arachnae";
import type { ThemeType } from "@jpbarela/arachnae";

export const colors = {
  black: "#242124",
  darkBlue: "#045077",
  lightBlue: "#007aa3",
  green: "#6D9F71",
  grey: "#847e89",
  red: "#BA1200",
  white: "#ecebe4",
};

export const theme: ThemeType = Object.assign(defaultTheme, {
  button: {
    primary: colors.lightBlue,
    color: colors.white,
  },
  color: colors.black,
  input: {
    borderColor: colors.lightBlue,
  },
});
