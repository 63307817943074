// @flow
import * as React from "react";
import { useAuthentication } from "../../Contexts";
import { Button, Page, Row, Title } from "@jpbarela/arachnae";
import { CreateThesisForm, ThesesTable } from "./Theses";
import { CreateTransactionForm, HoldingsTable } from "./Holdings";
import { Panel } from "./Panel";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export function Dashboard(): React.Node {
  const { environment } = useAuthentication();

  return (
    <Page>
      <Header />
      <Tabs>
        <TabList>
          <Tab>Monitor</Tab>
          <Tab>Evaluate</Tab>
          <Tab>Invest</Tab>
        </TabList>

        <Panel
          Body={HoldingsTable}
          Form={CreateTransactionForm}
          buttonName="Create Transaction"
          environment={environment}
          panelName="Holdings"
        />

        <Panel
          Body={ThesesTable}
          Form={CreateThesisForm}
          buttonName="Create Thesis"
          environment={environment}
          panelName="Theses"
        />
        <TabPanel>Coming soon</TabPanel>
      </Tabs>
    </Page>
  );
}

function Header() {
  const { logout, user } = useAuthentication();

  return (
    <Row justifyContent="space-between">
      <Title>Welcome {user.given_name} </Title>
      <Button
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
        name="Logout"
        style="link"
      />
    </Row>
  );
}
