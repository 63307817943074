/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTransactionInput = {|
  amount: number,
  clientMutationId?: ?string,
  date: any,
  shares: number,
  ticker: string,
|};
export type CreateTransactionForm_CreateTransactionMutationVariables = {|
  createTransactionInput: CreateTransactionInput
|};
export type CreateTransactionForm_CreateTransactionMutationResponse = {|
  +createTransaction: ?{|
    +holdingEdge: ?{|
      +thesis: ?{|
        +quick: string
      |},
      +costBasis: number,
      +shares: number,
      +node: ?{|
        +ticker: string,
        +name: string,
        +lastPrice: ?{|
          +price: number,
          +date: any,
        |},
      |},
    |},
    +errors: $ReadOnlyArray<{|
      +code: string,
      +description: ?string,
    |}>,
  |}
|};
export type CreateTransactionForm_CreateTransactionMutation = {|
  variables: CreateTransactionForm_CreateTransactionMutationVariables,
  response: CreateTransactionForm_CreateTransactionMutationResponse,
|};
*/


/*
mutation CreateTransactionForm_CreateTransactionMutation(
  $createTransactionInput: CreateTransactionInput!
) {
  createTransaction(input: $createTransactionInput) {
    holdingEdge {
      thesis {
        quick
        id
      }
      costBasis
      shares
      node {
        ticker
        name
        lastPrice {
          price
          date
        }
        id
      }
    }
    errors {
      code
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createTransactionInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "createTransactionInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quick",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costBasis",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shares",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticker",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "StockPrice",
  "kind": "LinkedField",
  "name": "lastPrice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTransactionForm_CreateTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTransactionPayload",
        "kind": "LinkedField",
        "name": "createTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoldingEdge",
            "kind": "LinkedField",
            "name": "holdingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Thesis",
                "kind": "LinkedField",
                "name": "thesis",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stock",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTransactionForm_CreateTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTransactionPayload",
        "kind": "LinkedField",
        "name": "createTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoldingEdge",
            "kind": "LinkedField",
            "name": "holdingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Thesis",
                "kind": "LinkedField",
                "name": "thesis",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stock",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8a013c9b2745181e52f92805704d403",
    "id": null,
    "metadata": {},
    "name": "CreateTransactionForm_CreateTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTransactionForm_CreateTransactionMutation(\n  $createTransactionInput: CreateTransactionInput!\n) {\n  createTransaction(input: $createTransactionInput) {\n    holdingEdge {\n      thesis {\n        quick\n        id\n      }\n      costBasis\n      shares\n      node {\n        ticker\n        name\n        lastPrice {\n          price\n          date\n        }\n        id\n      }\n    }\n    errors {\n      code\n      description\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'afc8dc0b466280cfa4833cfb9b617b7d';

module.exports = node;
