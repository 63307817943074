// @flow
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import type { IEnvironment } from "relay-runtime";
import { LoadingComponent } from "../LoadingComponent";
import { QueryRenderer } from "react-relay";
import { DataTable } from "@jpbarela/arachnae";
import { useHoldingsStyles } from "./styles";

type HoldingsTableProps = {
  environment: IEnvironment,
};

const columns = [
  {
    header: "Ticker",
    sortable: true,
  },
  {
    header: "Name",
    textAlign: "left",
  },
  {
    header: "Price",
    formatFunction: formatDollar,
  },
  {
    header: "Shares",
    formatFunction: formatShares,
  },
  {
    header: "Cost Basis",
    sortable: true,
    formatFunction: formatDollar,
  },
  {
    header: "Current Value",
    sortable: true,
    formatFunction: formatDollar,
  },
  {
    header: "Total Return",
    sortable: true,
    formatFunction: formatPercent,
  },
];

const missingString = "...";

function HoldingsDataTable({ rows }) {
  const data = convertGraphqlToArray(rows);

  return <DataTable data={data} columns={columns} borderWidth="0" />;
}

export function HoldingsTable({ environment }: HoldingsTableProps): React.Node {
  const classes = useHoldingsStyles();

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query HoldingsTableQuery {
          me {
            holdings(first: 50) @connection(key: "Holdings_holdings") {
              edges {
                thesis {
                  quick
                }
                costBasis
                shares
                node {
                  id
                  ticker
                  name
                  lastPrice {
                    price
                    date
                  }
                }
              }
            }
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <LoadingComponent className={classes.container} />;
        }
        return (
          <div>
            {props.me.holdings.edges.length ? (
              <HoldingsDataTable rows={props.me.holdings.edges} />
            ) : (
              <HoldingsCallToAction />
            )}
          </div>
        );
      }}
    />
  );
}

function HoldingsCallToAction() {
  const classes = useHoldingsStyles();

  return (
    <div className={classes.container}>
      No holdings found. Create a transaction!
    </div>
  );
}

function convertGraphqlToArray(rows) {
  return rows.map((row) => {
    if (row.node.lastPrice) {
      const price = row.node.lastPrice.price;
      const currentValue = price * row.shares;

      return [
        row.node.ticker,
        row.node.name,
        price,
        row.shares,
        row.costBasis,
        currentValue,
        currentValue / row.costBasis - 1,
      ];
    }

    return [
      row.node.ticker,
      row.node.name,
      undefined,
      row.shares,
      row.costBasis,
      undefined,
      undefined,
    ];
  });
}

function formatDollar(value) {
  if (value === undefined) {
    return missingString;
  }

  return `${value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}

function formatPercent(value) {
  if (value === undefined) {
    return missingString;
  }

  const classes = useHoldingsStyles();

  const spanClass =
    value < 0 ? classes.percentDecrease : classes.percentIncrease;
  return <span className={spanClass}>{`${(value * 100).toFixed(2)}%`}</span>;
}

function formatShares(value) {
  return value.toFixed(4);
}
