// @flow
import { colors } from "../../../theme";
import { createUseStyles } from "react-jss";

export const useThesesStyles: () => {
  container: string,
  error: string,
  name: string,
  ticker: string,
} = createUseStyles({
  container: {
    padding: "0 1rem",
  },
  error: {
    color: colors.red,
  },
  name: {
    width: "20rem",
  },
  ticker: {
    width: "5rem",
  },
});
