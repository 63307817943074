// @flow
import * as React from "react";
import { useHoldingsStyles } from "./styles";
import { commitMutation } from "react-relay";
import type { IEnvironment } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import {
  Button,
  DateInput,
  Form,
  NumericInput,
  Row,
  SubmitInput,
  TextInput,
} from "@jpbarela/arachnae";
import { colors } from "../../../theme";

type CreateTransactionFormProps = {
  onCancel: () => void,
  environment: IEnvironment,
};

function ErrorMessage({ error }) {
  if (error) {
    const classes = useHoldingsStyles();
    return <div className={classes.error}>{error}</div>;
  }

  return null;
}

export function CreateTransactionForm({
  onCancel,
  environment,
}: CreateTransactionFormProps): React.Node {
  const classes = useHoldingsStyles();
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState("");

  function handleCompletion(response) {
    setCreating(false);
    if (response.createTransaction.errors.length > 0) {
      setError(response.createTransaction.errors[0].description);
    } else {
      onCancel();
    }
  }

  function handleError(err) {
    setCreating(false);
    setError(err.message);
  }

  function submitMutation({ amount, date, shares, ticker }) {
    setCreating(true);

    const variables = {
      createTransactionInput: {
        amount,
        date,
        shares,
        ticker,
      },
    };

    commitMutation(environment, {
      mutation: createHoldingMutationGraphql,
      variables,
      configs: mutationConfigs,
      onCompleted: handleCompletion,
      onError: handleError,
    });
  }

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submitMutation}
        container={{ borderBottom: `solid 2px ${colors.lightBlue}` }}
      >
        <Row justifyContent="start">
          <TextInput
            name="ticker"
            required={true}
            placeholder="Ticker"
            width="6rem"
            testID="ticker"
          />
          <DateInput
            name="date"
            required={true}
            placeholder="Transaction date"
            width="10rem"
            testID="date"
          />
          <NumericInput
            name="shares"
            required={true}
            placeholder="Shares"
            width="10rem"
            testID="shares"
          />
          <NumericInput
            name="amount"
            required={true}
            placeholder="Amount invested"
            width="10rem"
            testID="amount"
          />
          <SubmitInput
            name={creating ? "Creating Transaction ..." : "Create Transaction"}
            disabled={creating}
            testID="create"
          />
          {!creating ? (
            <Button type="link" onClick={onCancel} name="Cancel" />
          ) : null}
          <ErrorMessage error={error} />
        </Row>
      </Form>
    </div>
  );
}

const mutationConfigs = [
  {
    type: "RANGE_ADD",
    parentID: "client:root:me",
    connectionInfo: [
      {
        key: "Holdings_holdings",
        rangeBehavior: "append",
      },
    ],
    edgeName: "holdingEdge",
  },
];

const createHoldingMutationGraphql = graphql`
  mutation CreateTransactionForm_CreateTransactionMutation(
    $createTransactionInput: CreateTransactionInput!
  ) {
    createTransaction(input: $createTransactionInput) {
      holdingEdge {
        thesis {
          quick
        }
        costBasis
        shares
        node {
          ticker
          name
          lastPrice {
            price
            date
          }
        }
      }
      errors {
        code
        description
      }
    }
  }
`;
