/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HoldingsTableQueryVariables = {||};
export type HoldingsTableQueryResponse = {|
  +me: {|
    +holdings: {|
      +edges: ?$ReadOnlyArray<?{|
        +thesis: ?{|
          +quick: string
        |},
        +costBasis: number,
        +shares: number,
        +node: ?{|
          +id: string,
          +ticker: string,
          +name: string,
          +lastPrice: ?{|
            +price: number,
            +date: any,
          |},
        |},
      |}>
    |}
  |}
|};
export type HoldingsTableQuery = {|
  variables: HoldingsTableQueryVariables,
  response: HoldingsTableQueryResponse,
|};
*/


/*
query HoldingsTableQuery {
  me {
    holdings(first: 50) {
      edges {
        thesis {
          quick
          id
        }
        costBasis
        shares
        node {
          id
          ticker
          name
          lastPrice {
            price
            date
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quick",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costBasis",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shares",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Stock",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticker",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StockPrice",
      "kind": "LinkedField",
      "name": "lastPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HoldingsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Investor",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": "holdings",
            "args": null,
            "concreteType": "StockConnection",
            "kind": "LinkedField",
            "name": "__Holdings_holdings_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StockEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thesis",
                    "kind": "LinkedField",
                    "name": "thesis",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HoldingsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Investor",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "StockConnection",
            "kind": "LinkedField",
            "name": "holdings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StockEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thesis",
                    "kind": "LinkedField",
                    "name": "thesis",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": "holdings(first:50)"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Holdings_holdings",
            "kind": "LinkedHandle",
            "name": "holdings"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "492916bb496b379bda358a34155ea7d6",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "me",
            "holdings"
          ]
        }
      ]
    },
    "name": "HoldingsTableQuery",
    "operationKind": "query",
    "text": "query HoldingsTableQuery {\n  me {\n    holdings(first: 50) {\n      edges {\n        thesis {\n          quick\n          id\n        }\n        costBasis\n        shares\n        node {\n          id\n          ticker\n          name\n          lastPrice {\n            price\n            date\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '787d5e4d9e3b83ec1cb41724b1f262ad';

module.exports = node;
